var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    _vm._b(
      {
        attrs: {
          "form-action-method": _vm.formActionWindow,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          "page-path": _vm.pagePath,
          title: _vm.title,
          "title-icon": "mdi-file-table",
          "initial-width": "4",
          "toolbar-actions": _vm.toolbarActions,
        },
        on: {
          "toolbar-action": function ($event) {
            return _vm.toolbarMethod($event)
          },
        },
      },
      "asyent-window",
      _vm.$attrs,
      false
    ),
    [
      _vm.hasHistory
        ? _c("AsyhubHistory", {
            ref: "maritimeHistory",
            attrs: {
              id: _vm.id,
              "items-reload": _vm.historyReload,
              "component-name": "MaritimeConsignmentsForm",
              name: "consignment-history",
              "service-name": "cargo",
            },
            model: {
              value: _vm.showHistory,
              callback: function ($$v) {
                _vm.showHistory = $$v
              },
              expression: "showHistory",
            },
          })
        : _vm._e(),
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "view-mode": _vm.viewMode,
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _vm.rop !== "new"
                          ? _c("asyent-form-info", {
                              attrs: { "no-title": "", "no-maximize": "" },
                              model: {
                                value: _vm.formData,
                                callback: function ($$v) {
                                  _vm.formData = $$v
                                },
                                expression: "formData",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "General", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value: _vm.formDataF["id"],
                                                label: "Consignment ID",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.formDataF["originatorId"],
                                                  _vm.formDataF[
                                                    "originatorName"
                                                  ],
                                                ],
                                                label: "Originator",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value:
                                                  _vm.formDataF["providerName"],
                                                label: "Provider",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value:
                                                  _vm.formDataF[
                                                    "providerObjectId"
                                                  ],
                                                label:
                                                  "Provider Consignment ID",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "3" } },
                                          [
                                            _vm.viewMode
                                              ? _c("asyent-form-display", {
                                                  attrs: {
                                                    value: [
                                                      _vm.formDataF[
                                                        "loadingPlace"
                                                      ]["code"],
                                                      _vm.formDataF[
                                                        "loadingPlace"
                                                      ]["name"],
                                                      _vm.formDataF[
                                                        "loadingPlace"
                                                      ]["countryName"],
                                                    ],
                                                    label:
                                                      _vm.ae$lng(
                                                        "loadingPlace"
                                                      ),
                                                  },
                                                })
                                              : _c("asyent-form-finder", {
                                                  ref: "loadingPlace",
                                                  attrs: {
                                                    "working-date":
                                                      _vm.workingDate,
                                                    "view-mode": _vm.viewMode,
                                                    "form-errors":
                                                      _vm.formErrors,
                                                    "form-rules":
                                                      _vm.formFieldsRules,
                                                    "is-disabled-func":
                                                      _vm.isDisabled,
                                                    "service-name": "catalog",
                                                    catalog:
                                                      "CatalogPlaceOfLoading",
                                                    "data-fields":
                                                      _vm.dataFields,
                                                    props: {},
                                                    label:
                                                      _vm.ae$lng(
                                                        "loadingPlace"
                                                      ),
                                                    "item-value": "code",
                                                    "item-text": "description",
                                                    name: "loadingPlace",
                                                    "as-text": "",
                                                    "is-not-cacheable": true,
                                                    "pre-poplated": false,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formDataF[
                                                        "loadingPlace"
                                                      ]["code"],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formDataF[
                                                          "loadingPlace"
                                                        ],
                                                        "code",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formDataF['loadingPlace']['code']",
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "3" } },
                                          [
                                            _vm.viewMode
                                              ? _c("asyent-form-display", {
                                                  attrs: {
                                                    value: [
                                                      _vm.formDataF[
                                                        "dischargePlace"
                                                      ]["code"],
                                                      _vm.formDataF[
                                                        "dischargePlace"
                                                      ]["name"],
                                                      _vm.formDataF[
                                                        "dischargePlace"
                                                      ]["countryName"],
                                                    ],
                                                    label:
                                                      _vm.ae$lng(
                                                        "dischargePlace"
                                                      ),
                                                  },
                                                })
                                              : _c("asyent-form-finder", {
                                                  ref: "dischargePlace",
                                                  attrs: {
                                                    "working-date":
                                                      _vm.workingDate,
                                                    "view-mode": _vm.viewMode,
                                                    "form-errors":
                                                      _vm.formErrors,
                                                    "form-rules":
                                                      _vm.formFieldsRules,
                                                    "is-disabled-func":
                                                      _vm.isDisabled,
                                                    "service-name": "catalog",
                                                    catalog:
                                                      "CatalogPlaceOfLoading",
                                                    "data-fields":
                                                      _vm.dataFields,
                                                    props: {},
                                                    label:
                                                      _vm.ae$lng(
                                                        "dischargePlace"
                                                      ),
                                                    "item-value": "code",
                                                    "item-text": "description",
                                                    name: "dischargePlace",
                                                    "as-text": "",
                                                    "is-not-cacheable": true,
                                                    "pre-poplated": false,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formDataF[
                                                        "dischargePlace"
                                                      ]["code"],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formDataF[
                                                          "dischargePlace"
                                                        ],
                                                        "code",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formDataF['dischargePlace']['code']",
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _vm.viewMode
                                              ? _c("asyent-form-display", {
                                                  attrs: {
                                                    value: [
                                                      _vm.formDataF["flow"],
                                                    ],
                                                    label: _vm.ae$lng("flow"),
                                                  },
                                                })
                                              : _c("asyent-form-catalog", {
                                                  attrs: {
                                                    sortable: "",
                                                    "view-mode": _vm.viewMode,
                                                    "form-errors":
                                                      _vm.formErrors,
                                                    "form-rules":
                                                      _vm.formFieldsRules,
                                                    "is-disabled-func":
                                                      _vm.isDisabled,
                                                    "service-name": "catalog",
                                                    catalog:
                                                      "CatalogCustomsFlow",
                                                    "show-item-code": "",
                                                    label: _vm.ae$lng("flow"),
                                                    "item-value": "code",
                                                    "item-text": "description",
                                                    name: "flow",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formDataF["flow"],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formDataF,
                                                        "flow",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formDataF['flow']",
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-chip",
                                              { attrs: { color: "success" } },
                                              [_vm._v("NO DANGEROUS GOODS")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "8", md: "8" } },
                                          [
                                            _c(
                                              "asyent-card",
                                              {
                                                attrs: {
                                                  title: "Information",
                                                  flat: "",
                                                  "border-color": 2,
                                                  "with-border": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "4",
                                                                },
                                                              },
                                                              [
                                                                _vm.viewMode
                                                                  ? _c(
                                                                      "asyent-form-display",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            [
                                                                              _vm
                                                                                .formDataF[
                                                                                "customsOffice"
                                                                              ][
                                                                                "code"
                                                                              ],
                                                                              _vm
                                                                                .formDataF[
                                                                                "customsOffice"
                                                                              ][
                                                                                "name"
                                                                              ],
                                                                            ],
                                                                          label:
                                                                            _vm.ae$lng(
                                                                              "Office Code"
                                                                            ),
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "asyent-form-catalog",
                                                                      {
                                                                        attrs: {
                                                                          sortable:
                                                                            "",
                                                                          "view-mode":
                                                                            _vm.viewMode,
                                                                          "form-errors":
                                                                            _vm.formErrors,
                                                                          "form-rules":
                                                                            _vm.formFieldsRules,
                                                                          "is-disabled-func":
                                                                            _vm.isDisabled,
                                                                          "service-name":
                                                                            "catalog",
                                                                          catalog:
                                                                            "CatalogCustomsOffice",
                                                                          "show-item-code":
                                                                            "",
                                                                          label:
                                                                            _vm.ae$lng(
                                                                              "Office Code"
                                                                            ),
                                                                          "item-value":
                                                                            "code",
                                                                          "item-text":
                                                                            "name",
                                                                          name: "officeCode",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "customsOffice"
                                                                            ][
                                                                              "code"
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .formDataF[
                                                                                  "customsOffice"
                                                                                ],
                                                                                "code",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "formDataF['customsOffice']['code']",
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "4",
                                                                },
                                                              },
                                                              [
                                                                _vm.viewMode
                                                                  ? _c(
                                                                      "asyent-form-display",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            [
                                                                              _vm
                                                                                .formDataF[
                                                                                "idCargoVoyage"
                                                                              ][
                                                                                "departurePort"
                                                                              ][
                                                                                "code"
                                                                              ],
                                                                              _vm
                                                                                .formDataF[
                                                                                "idCargoVoyage"
                                                                              ][
                                                                                "departurePort"
                                                                              ][
                                                                                "name"
                                                                              ],
                                                                              _vm
                                                                                .formDataF[
                                                                                "idCargoVoyage"
                                                                              ][
                                                                                "departurePort"
                                                                              ][
                                                                                "countryName"
                                                                              ],
                                                                            ],
                                                                          label:
                                                                            "Departure Port",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "asyent-form-finder",
                                                                      {
                                                                        ref: "departurePort",
                                                                        attrs: {
                                                                          "working-date":
                                                                            _vm.workingDate,
                                                                          "view-mode":
                                                                            _vm.viewMode,
                                                                          "form-errors":
                                                                            _vm.formErrors,
                                                                          "form-rules":
                                                                            _vm.formFieldsRules,
                                                                          "is-disabled-func":
                                                                            _vm.isDisabled,
                                                                          "service-name":
                                                                            "catalog",
                                                                          catalog:
                                                                            "CatalogPlaceOfLoading",
                                                                          "data-fields":
                                                                            _vm.dataFields,
                                                                          props:
                                                                            {},
                                                                          label:
                                                                            _vm.ae$lng(
                                                                              "Departure Port"
                                                                            ),
                                                                          "item-value":
                                                                            "code",
                                                                          "item-text":
                                                                            "description",
                                                                          name: "departurePort",
                                                                          "as-text":
                                                                            "",
                                                                          "is-not-cacheable": true,
                                                                          "pre-poplated": false,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "idCargoVoyage"
                                                                            ][
                                                                              "departurePort"
                                                                            ][
                                                                              "code"
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .formDataF[
                                                                                  "idCargoVoyage"
                                                                                ][
                                                                                  "departurePort"
                                                                                ],
                                                                                "code",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "formDataF['idCargoVoyage']['departurePort']['code']",
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-text",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "Voyage Number"
                                                                        ),
                                                                      name: "voyageNumber",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "voyageNumber"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "idCargoVoyage"
                                                                            ],
                                                                            "voyageNumber",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['idCargoVoyage']['voyageNumber']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "4",
                                                                  lg: "4",
                                                                },
                                                              },
                                                              [
                                                                _vm.viewMode
                                                                  ? _c(
                                                                      "asyent-form-display",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ][
                                                                              "transportDocumentReference"
                                                                            ],
                                                                          label:
                                                                            _vm.ae$lng(
                                                                              "consignmentReference"
                                                                            ),
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "asyent-form-text",
                                                                      {
                                                                        attrs: {
                                                                          "view-mode":
                                                                            _vm.viewMode,
                                                                          "form-errors":
                                                                            _vm.formErrors,
                                                                          "form-rules":
                                                                            _vm.formFieldsRules,
                                                                          "is-disabled-func":
                                                                            _vm.isDisabled,
                                                                          label:
                                                                            _vm.ae$lng(
                                                                              "trasportDocumentReference"
                                                                            ),
                                                                          name: "consignmentReference",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ][
                                                                              "transportDocumentReference"
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .formDataF[
                                                                                  "transportDocument"
                                                                                ],
                                                                                "transportDocumentReference",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "\n                                    formDataF['transportDocument']['transportDocumentReference']\n                                  ",
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "4",
                                                                },
                                                              },
                                                              [
                                                                _vm.viewMode
                                                                  ? _c(
                                                                      "asyent-form-display",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ][
                                                                              "transportDocumentType"
                                                                            ],
                                                                          label:
                                                                            "Document Type",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "asyent-form-catalog",
                                                                      {
                                                                        attrs: {
                                                                          sortable:
                                                                            "",
                                                                          "view-mode":
                                                                            _vm.viewMode,
                                                                          "form-errors":
                                                                            _vm.formErrors,
                                                                          "form-rules":
                                                                            _vm.formFieldsRules,
                                                                          "is-disabled-func":
                                                                            _vm.isDisabled,
                                                                          "service-name":
                                                                            "catalog",
                                                                          catalog:
                                                                            "CatalogTransportDocument",
                                                                          "show-item-code":
                                                                            "",
                                                                          label:
                                                                            _vm.ae$lng(
                                                                              "Consignment Type"
                                                                            ),
                                                                          "item-value":
                                                                            "code",
                                                                          "item-text":
                                                                            "description",
                                                                          name: "consignmentType",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ][
                                                                              "transportDocumentType"
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .formDataF[
                                                                                  "transportDocument"
                                                                                ],
                                                                                "transportDocumentType",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "formDataF['transportDocument']['transportDocumentType']",
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                  lg: "4",
                                                                },
                                                              },
                                                              [
                                                                _vm.viewMode
                                                                  ? _c(
                                                                      "asyent-form-display",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            [
                                                                              _vm
                                                                                .formDataF[
                                                                                "transportMeans"
                                                                              ][
                                                                                "idNumber"
                                                                              ],
                                                                              _vm
                                                                                .formDataF[
                                                                                "transportMeans"
                                                                              ][
                                                                                "name"
                                                                              ],
                                                                            ],
                                                                          label:
                                                                            "Transport Means",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "asyent-form-catalog",
                                                                      {
                                                                        attrs: {
                                                                          sortable:
                                                                            "",
                                                                          "view-mode":
                                                                            _vm.viewMode,
                                                                          "form-errors":
                                                                            _vm.formErrors,
                                                                          "form-rules":
                                                                            _vm.formFieldsRules,
                                                                          "is-disabled-func":
                                                                            _vm.isDisabled,
                                                                          "service-name":
                                                                            "catalog",
                                                                          catalog:
                                                                            "CatalogModeTransport",
                                                                          "show-item-code":
                                                                            "",
                                                                          label:
                                                                            _vm.ae$lng(
                                                                              "transportMeans"
                                                                            ),
                                                                          "item-value":
                                                                            "code",
                                                                          "item-text":
                                                                            "description",
                                                                          name: "transportMeans",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportMeans"
                                                                            ][
                                                                              "idNumber"
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .formDataF[
                                                                                  "transportMeans"
                                                                                ],
                                                                                "idNumber",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "formDataF['transportMeans']['idNumber']",
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "4",
                                                                  lg: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-text",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "masterTransportDocumentReference"
                                                                        ),
                                                                      name: "masterTransportDocumentReference",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "masterTransportDocumentReference"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ],
                                                                            "masterTransportDocumentReference",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "\n                                    formDataF['transportDocument'][\n                                      'masterTransportDocumentReference'\n                                    ]\n                                  ",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "4",
                                                                  lg: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-text",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "uniqueConsignmentReference"
                                                                        ),
                                                                      name: "uniqueConsignmentReference",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "ucr"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ],
                                                                            "ucr",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['transportDocument']['ucr']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "4",
                                                                  lg: "4",
                                                                },
                                                              },
                                                              [
                                                                _vm.viewMode
                                                                  ? _c(
                                                                      "asyent-form-display",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "carrierBookingNumber"
                                                                            ],
                                                                          label:
                                                                            "Carrier Booking Number",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "asyent-form-text",
                                                                      {
                                                                        attrs: {
                                                                          "view-mode":
                                                                            _vm.viewMode,
                                                                          "form-errors":
                                                                            _vm.formErrors,
                                                                          "form-rules":
                                                                            _vm.formFieldsRules,
                                                                          "is-disabled-func":
                                                                            _vm.isDisabled,
                                                                          label:
                                                                            _vm.ae$lng(
                                                                              "Carrier Booking Number"
                                                                            ),
                                                                          name: "Carrier Booking Number",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "carrierBookingNumber"
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.formDataF,
                                                                                "carrierBookingNumber",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "formDataF['carrierBookingNumber']",
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "4", md: "4" } },
                                          [
                                            _c(
                                              "asyent-card",
                                              {
                                                attrs: {
                                                  title: "Date & Times\n",
                                                  flat: "",
                                                  "border-color": 2,
                                                  "with-border": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-date",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "departureDate"
                                                                        ),
                                                                      name: "departureDate",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "departureDate"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "idCargoVoyage"
                                                                            ],
                                                                            "departureDate",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['idCargoVoyage']['departureDate']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "12",
                                                                  lg: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-date",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "estimatedTimeOfArrival"
                                                                        ),
                                                                      type: "datetime-local",
                                                                      name: "estimatedTimeOfArrival",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "estimatedTimeOfArrival"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.formDataF,
                                                                            "estimatedTimeOfArrival",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['estimatedTimeOfArrival']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "12",
                                                                  lg: "12",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-date",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "actualTimeOfArrival"
                                                                        ),
                                                                      type: "datetime-local",
                                                                      name: "actualTimeOfArrival",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "actualTimeOfArrival"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.formDataF,
                                                                            "actualTimeOfArrival",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['actualTimeOfArrival']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "12" } },
                                          [
                                            _c(
                                              "asyent-card",
                                              {
                                                attrs: {
                                                  title: "Transport Document",
                                                  flat: "",
                                                  "border-color": 2,
                                                  "with-border": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6",
                                                                },
                                                              },
                                                              [
                                                                _vm.viewMode
                                                                  ? _c(
                                                                      "asyent-form-display",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ][
                                                                              "contractCarriageCondition"
                                                                            ],
                                                                          label:
                                                                            "Contract Carriage Condition",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "asyent-form-text",
                                                                      {
                                                                        attrs: {
                                                                          "view-mode":
                                                                            _vm.viewMode,
                                                                          "form-errors":
                                                                            _vm.formErrors,
                                                                          "form-rules":
                                                                            _vm.formFieldsRules,
                                                                          "is-disabled-func":
                                                                            _vm.isDisabled,
                                                                          label:
                                                                            _vm.ae$lng(
                                                                              "Contract Carriage Condition"
                                                                            ),
                                                                          name: "Contract Carriage Condition",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ][
                                                                              "contractCarriageCondition"
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .formDataF[
                                                                                  "transportDocument"
                                                                                ],
                                                                                "contractCarriageCondition",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "\n                                    formDataF['transportDocument']['contractCarriageCondition']\n                                  ",
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-date",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "acceptanceDate"
                                                                        ),
                                                                      name: "acceptanceDate",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "acceptanceDate"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ],
                                                                            "acceptanceDate",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['transportDocument']['acceptanceDate']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-date",
                                                                  {
                                                                    attrs: {
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      label:
                                                                        _vm.ae$lng(
                                                                          "issueDate"
                                                                        ),
                                                                      name: "issueDate",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "issueDate"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ],
                                                                            "issueDate",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['transportDocument']['issueDate']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.viewMode,
                                                                      label:
                                                                        "Negotiable",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "negotiable"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ],
                                                                            "negotiable",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['transportDocument']['negotiable']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.viewMode,
                                                                      label:
                                                                        "eBL",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "eBL"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ],
                                                                            "eBL",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['transportDocument']['eBL']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-checkbox",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.viewMode,
                                                                      label:
                                                                        "Part Bill",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "partBill"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .formDataF[
                                                                              "transportDocument"
                                                                            ],
                                                                            "partBill",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['transportDocument']['partBill']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "12" } },
                                          [
                                            _c(
                                              "asyent-card",
                                              {
                                                attrs: {
                                                  title: "Transport Plan",
                                                  flat: "",
                                                  "border-color": 2,
                                                  "with-border": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("v-data-table", {
                                                          staticClass:
                                                            "pa-0 ma-0",
                                                          attrs: {
                                                            headers:
                                                              _vm.portcallTableHeaders,
                                                            items:
                                                              _vm.formDataF[
                                                                "ports"
                                                              ],
                                                            "items-per-page":
                                                              -1,
                                                            "hide-default-footer":
                                                              "",
                                                            "multi-sort": "",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "item.vehicleId",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item[
                                                                          "vehicleId"
                                                                        ]
                                                                      ) +
                                                                      " | " +
                                                                      _vm._s(
                                                                        item[
                                                                          "vehicleName"
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "item.locationCode",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item[
                                                                          "locationCode"
                                                                        ]
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        item[
                                                                          "locationName"
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "bgWhite",
                                          title: "Carrier",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("asyent-form-text", {
                                                          attrs: {
                                                            "view-mode":
                                                              _vm.viewMode,
                                                            "form-errors":
                                                              _vm.formErrors,
                                                            "form-rules":
                                                              _vm.formFieldsRules,
                                                            "is-disabled-func":
                                                              _vm.isDisabled,
                                                            label:
                                                              _vm.ae$lng(
                                                                "SCAC Id."
                                                              ),
                                                            name: "carrierId",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.formDataF[
                                                                "carrier"
                                                              ]["id"],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.formDataF[
                                                                  "carrier"
                                                                ],
                                                                "id",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "formDataF['carrier']['id']",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("asyent-form-text", {
                                                          attrs: {
                                                            "view-mode":
                                                              _vm.viewMode,
                                                            "form-errors":
                                                              _vm.formErrors,
                                                            "form-rules":
                                                              _vm.formFieldsRules,
                                                            "is-disabled-func":
                                                              _vm.isDisabled,
                                                            label:
                                                              _vm.ae$lng(
                                                                "Name & Address"
                                                              ),
                                                            name: "carrierName",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.formDataF[
                                                                "carrier"
                                                              ]["name"],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.formDataF[
                                                                  "carrier"
                                                                ],
                                                                "name",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "formDataF['carrier']['name']",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "bgWhite",
                                          title: "Consignor",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "6",
                                                          lg: "6",
                                                        },
                                                      },
                                                      [
                                                        _c("asyent-form-text", {
                                                          attrs: {
                                                            "view-mode":
                                                              _vm.viewMode,
                                                            "form-errors":
                                                              _vm.formErrors,
                                                            "form-rules":
                                                              _vm.formFieldsRules,
                                                            "is-disabled-func":
                                                              _vm.isDisabled,
                                                            label:
                                                              _vm.ae$lng(
                                                                "Eori No."
                                                              ),
                                                            name: "consignorId",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.formDataF[
                                                                "consignor"
                                                              ]["id"],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.formDataF[
                                                                  "consignor"
                                                                ],
                                                                "id",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "formDataF['consignor']['id']",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "6",
                                                          lg: "6",
                                                        },
                                                      },
                                                      [
                                                        _c("asyent-form-text", {
                                                          attrs: {
                                                            "view-mode":
                                                              _vm.viewMode,
                                                            "form-errors":
                                                              _vm.formErrors,
                                                            "form-rules":
                                                              _vm.formFieldsRules,
                                                            "is-disabled-func":
                                                              _vm.isDisabled,
                                                            label:
                                                              _vm.ae$lng(
                                                                "Provider Ref."
                                                              ),
                                                            name: "consignorProviderRef",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.formDataF[
                                                                "consignor"
                                                              ]["providerRef"],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.formDataF[
                                                                  "consignor"
                                                                ],
                                                                "providerRef",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "formDataF['consignor']['providerRef']",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("asyent-form-text", {
                                                          attrs: {
                                                            "view-mode":
                                                              _vm.viewMode,
                                                            "form-errors":
                                                              _vm.formErrors,
                                                            "form-rules":
                                                              _vm.formFieldsRules,
                                                            "is-disabled-func":
                                                              _vm.isDisabled,
                                                            label:
                                                              _vm.ae$lng(
                                                                "Name & Address"
                                                              ),
                                                            name: "consignorNameAddress",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.formDataF[
                                                                "consignor"
                                                              ]["name"],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.formDataF[
                                                                  "consignor"
                                                                ],
                                                                "name",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "formDataF['consignor']['name']",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "bgWhite",
                                          title: "Consignee",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "6",
                                                          lg: "6",
                                                        },
                                                      },
                                                      [
                                                        _c("asyent-form-text", {
                                                          attrs: {
                                                            "view-mode":
                                                              _vm.viewMode,
                                                            "form-errors":
                                                              _vm.formErrors,
                                                            "form-rules":
                                                              _vm.formFieldsRules,
                                                            "is-disabled-func":
                                                              _vm.isDisabled,
                                                            label:
                                                              _vm.ae$lng(
                                                                "Eori No."
                                                              ),
                                                            name: "consigneeId",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.formDataF[
                                                                "consignee"
                                                              ]["id"],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.formDataF[
                                                                  "consignee"
                                                                ],
                                                                "id",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "formDataF['consignee']['id']",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "6",
                                                          lg: "6",
                                                        },
                                                      },
                                                      [
                                                        _c("asyent-form-text", {
                                                          attrs: {
                                                            "view-mode":
                                                              _vm.viewMode,
                                                            "form-errors":
                                                              _vm.formErrors,
                                                            "form-rules":
                                                              _vm.formFieldsRules,
                                                            "is-disabled-func":
                                                              _vm.isDisabled,
                                                            label:
                                                              _vm.ae$lng(
                                                                "Provider Ref"
                                                              ),
                                                            name: "consigneeProviderRef",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.formDataF[
                                                                "consignee"
                                                              ]["providerRef"],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.formDataF[
                                                                  "consignee"
                                                                ],
                                                                "providerRef",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "formDataF['consignee']['providerRef']",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("asyent-form-text", {
                                                          attrs: {
                                                            "view-mode":
                                                              _vm.viewMode,
                                                            "form-errors":
                                                              _vm.formErrors,
                                                            "form-rules":
                                                              _vm.formFieldsRules,
                                                            "is-disabled-func":
                                                              _vm.isDisabled,
                                                            label:
                                                              _vm.ae$lng(
                                                                "Name & Address"
                                                              ),
                                                            name: "consigneeName",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.formDataF[
                                                                "consignee"
                                                              ]["name"],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.formDataF[
                                                                  "consignee"
                                                                ],
                                                                "name",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "formDataF['consignee']['name']",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "bgWhite",
                                          title: "Notify Party",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "6",
                                                          lg: "6",
                                                        },
                                                      },
                                                      [
                                                        _c("asyent-form-text", {
                                                          attrs: {
                                                            "view-mode":
                                                              _vm.viewMode,
                                                            "form-errors":
                                                              _vm.formErrors,
                                                            "form-rules":
                                                              _vm.formFieldsRules,
                                                            "is-disabled-func":
                                                              _vm.isDisabled,
                                                            label:
                                                              _vm.ae$lng(
                                                                "Eori No."
                                                              ),
                                                            name: "notifyPartyId",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.formDataF[
                                                                "notifyParty"
                                                              ]["id"],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.formDataF[
                                                                  "notifyParty"
                                                                ],
                                                                "id",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "formDataF['notifyParty']['id']",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "6",
                                                          lg: "6",
                                                        },
                                                      },
                                                      [
                                                        _c("asyent-form-text", {
                                                          attrs: {
                                                            "view-mode":
                                                              _vm.viewMode,
                                                            "form-errors":
                                                              _vm.formErrors,
                                                            "form-rules":
                                                              _vm.formFieldsRules,
                                                            "is-disabled-func":
                                                              _vm.isDisabled,
                                                            label:
                                                              _vm.ae$lng(
                                                                "Provider Ref"
                                                              ),
                                                            name: "notifyPartyProviderRef",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.formDataF[
                                                                "notifyParty"
                                                              ]["providerRef"],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.formDataF[
                                                                  "notifyParty"
                                                                ],
                                                                "providerRef",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "formDataF['notifyParty']['providerRef']",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("asyent-form-text", {
                                                          attrs: {
                                                            "view-mode":
                                                              _vm.viewMode,
                                                            "form-errors":
                                                              _vm.formErrors,
                                                            "form-rules":
                                                              _vm.formFieldsRules,
                                                            "is-disabled-func":
                                                              _vm.isDisabled,
                                                            label:
                                                              _vm.ae$lng(
                                                                "Name & Address"
                                                              ),
                                                            name: "notifyPartyName",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.formDataF[
                                                                "notifyParty"
                                                              ]["name"],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.formDataF[
                                                                  "notifyParty"
                                                                ],
                                                                "name",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "formDataF['notifyParty']['name']",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Risk Assessment Result",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          title: "Pre-Loading Selectivity",
                                          flat: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "plpSystemRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value:
                                                      _vm.showRisk(
                                                        "plpSystemRiskLevel"
                                                      ),
                                                    label: "System Risk",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "plpOfficerRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "plpOfficerRiskLevel"
                                                    ),
                                                    label: "Officer Decision",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          title: "Pre-Arrival Selectivity",
                                          flat: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "papSystemRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value:
                                                      _vm.showRisk(
                                                        "papSystemRiskLevel"
                                                      ),
                                                    label: "System Risk",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "papOfficerRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "papOfficerRiskLevel"
                                                    ),
                                                    label: "Officer Decision",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          title: "Post-Arrival Selectivity",
                                          flat: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "postSystemRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "postSystemRiskLevel"
                                                    ),
                                                    label: "System Risk",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "postOfficerRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "postOfficerRiskLevel"
                                                    ),
                                                    label: "Officer Decision",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          extended: "",
                                          expandable: "",
                                          title: "Selectivity Results",
                                        },
                                      },
                                      [
                                        _c("v-data-table", {
                                          staticClass: "pa-0 ma-0",
                                          attrs: {
                                            headers:
                                              _vm.selectivityTableHeaders,
                                            items:
                                              _vm.formDataF[
                                                "selectivityResults"
                                              ],
                                            "items-per-page": -1,
                                            "hide-default-footer": "",
                                            "multi-sort": "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item.riskAnalysisCategory",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.selectivityDialog = true
                                                          _vm.selectivityItem =
                                                            item
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "mdi-folder-open"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item[
                                                              "riskAnalysisCategory"
                                                            ]
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                        _c(
                                          "v-dialog",
                                          {
                                            attrs: {
                                              fullscreen:
                                                _vm.$vuetify.breakpoint
                                                  .smAndDown,
                                              persistent: "",
                                              scrollable: "",
                                              transition:
                                                "dialog-bottom-transition",
                                            },
                                            model: {
                                              value: _vm.selectivityDialog,
                                              callback: function ($$v) {
                                                _vm.selectivityDialog = $$v
                                              },
                                              expression: "selectivityDialog",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              { attrs: { color: "bgWhite" } },
                                              [
                                                _c(
                                                  "v-toolbar",
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                      "max-height": "56",
                                                    },
                                                  },
                                                  [
                                                    _c("v-toolbar-title", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.selectivityItem[
                                                            "riskAnalysisCategory"
                                                          ]
                                                        ) +
                                                          " Risk Analysis on " +
                                                          _vm._s(
                                                            _vm.selectivityItem[
                                                              "eventTypeName"
                                                            ]
                                                          )
                                                      ),
                                                    ]),
                                                    _c("v-spacer"),
                                                    _c("asyent-button", {
                                                      attrs: {
                                                        "button-icon":
                                                          "mdi-close",
                                                        "button-tooltip":
                                                          "Cancel",
                                                        dark: "",
                                                        icon: "",
                                                      },
                                                      on: {
                                                        confirmed: function (
                                                          $event
                                                        ) {
                                                          _vm.selectivityDialog = false
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .selectivityItem[
                                                                      "triggerTime"
                                                                    ],
                                                                  label:
                                                                    "Trigger Time",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .selectivityItem[
                                                                      "systemRiskLevel"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    "System Risk Level",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .selectivityItem[
                                                                      "riskAnalysisResult"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    "Risk Analysis Result",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .selectivityItem[
                                                                      "officerRiskLevel"
                                                                    ],
                                                                  label:
                                                                    "Officer Risk Assessment",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-card",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    "Hit Criteria",
                                                                  flat: "",
                                                                  "border-color": 2,
                                                                  "with-border":
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-data-table",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0",
                                                                    attrs: {
                                                                      headers:
                                                                        _vm.criteriaTableHeaders,
                                                                      items:
                                                                        _vm
                                                                          .selectivityItem[
                                                                          "hitCriteria"
                                                                        ],
                                                                      "items-per-page":
                                                                        -1,
                                                                      "hide-default-footer":
                                                                        "",
                                                                      "multi-sort":
                                                                        "",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-card",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    "Officer Risk Description",
                                                                  flat: "",
                                                                  "border-color": 2,
                                                                  "with-border":
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .selectivityItem[
                                                                          "officerRiskDescription"
                                                                        ],
                                                                      label: "",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-card",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    "Selected Controls",
                                                                  flat: "",
                                                                  "border-color": 2,
                                                                  "with-border":
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-select",
                                                                  {
                                                                    attrs: {
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      "items-reload":
                                                                        _vm.controlTypesReload,
                                                                      "enable-select-all":
                                                                        "",
                                                                      "item-text":
                                                                        "description",
                                                                      "item-value":
                                                                        "code",
                                                                      label: "",
                                                                      multiple:
                                                                        "",
                                                                      name: "controlTypes",
                                                                      sortable:
                                                                        "",
                                                                      title:
                                                                        "Control Types",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .selectivityItem[
                                                                          "controlTypes"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.selectivityItem,
                                                                            "controlTypes",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "selectivityItem['controlTypes']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Controls Results",
                            },
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.controlTableHeaders,
                                items: _vm.formDataF["consignmentControls"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.id",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "form-display-div" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: _vm.showControlLink(
                                                  item["id"]
                                                ),
                                                tag: "a",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v("mdi-folder-open")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "form-display-text form-display-link",
                                                  staticStyle: {
                                                    color: "unset",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item["controlType"]
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("items-consignment-items", {
                          attrs: {
                            "is-disabled-func": _vm.isDisabled,
                            "item-headings": _vm.itemTableHeaders,
                            "item-values": [
                              "itemId",
                              "hsCode",
                              ["transportPackages"]["packageNumber"],
                              "grossWeight",
                              "description",
                            ],
                            readonly: _vm.isReadonly,
                            "view-mode": _vm.viewMode,
                            name: "ConsignmentItemList",
                            title: _vm.ae$lng("ConsignmentItemList"),
                          },
                          model: {
                            value: _vm.formDataF["consignmentItems"],
                            callback: function ($$v) {
                              _vm.$set(_vm.formDataF, "consignmentItems", $$v)
                            },
                            expression: "formDataF['consignmentItems']",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("items-transport-equipments", {
                          attrs: {
                            "is-disabled-func": _vm.isDisabled,
                            "item-headings": _vm.equipmentTableHeaders,
                            "item-values": ["equipmentNumber", "equipmentType"],
                            readonly: _vm.isReadonly,
                            "view-mode": _vm.viewMode,
                            rop: _vm.rop,
                            name: "transportEquipmentList",
                            title: _vm.ae$lng("transportEquipmentList"),
                          },
                          model: {
                            value: _vm.formDataF["transportEquipments"],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formDataF,
                                "transportEquipments",
                                $$v
                              )
                            },
                            expression: "formDataF['transportEquipments']",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Additional Information",
                            },
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.addInfoTableHeaders,
                                items: _vm.formDataF["additionalInformation"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Documents",
                            },
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.documentTableHeaders,
                                items: _vm.formDataF["documents"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.documentId",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "form-display-div" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: _vm.showDocumentLink(
                                                  item["documentId"]
                                                ),
                                                tag: "a",
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "text-decoration": "unset",
                                                  },
                                                  attrs: { color: "primary" },
                                                },
                                                [_vm._v("mdi-file-link")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "form-display-text form-display-link",
                                                  staticStyle: {
                                                    color: "unset",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item["documentId"]
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Events",
                            },
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.eventTableHeaders,
                                items: _vm.formDataF["events"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.eventId",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "form-display-div" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: _vm.showEventLink(
                                                  item["eventId"]
                                                ),
                                                tag: "a",
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "text-decoration": "unset",
                                                  },
                                                  attrs: { color: "primary" },
                                                },
                                                [_vm._v("mdi-file-link")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "form-display-text form-display-link",
                                                  staticStyle: {
                                                    color: "unset",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item["eventId"]) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.rop !== "history" ? _c("asyent-toc") : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }