var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c(
        "asyent-card",
        {
          staticClass: "fill-height",
          attrs: { title: _vm.title, expandable: "" },
          scopedSlots: _vm._u(
            [
              !_vm.readonly && !_vm.isDisabledFunc(_vm.name) && !_vm.noNew
                ? {
                    key: "toolbar-actions",
                    fn: function () {
                      return [
                        _c("csv-import", {
                          attrs: {
                            "dialog-title": _vm.ae$lng("Upload"),
                            "map-fields": _vm.mapItems,
                            content: _vm.value2,
                            unique: true,
                            key1: "hsCode",
                          },
                          on: {
                            save: function (r) {
                              return _vm.updateImportItemsList(r, _vm.value2)
                            },
                          },
                        }),
                        _c("asyent-button", {
                          attrs: {
                            id: "v-step-items",
                            "button-icon": "mdi-plus",
                            "button-tooltip": _vm.ae$lng(
                              "Add Consignment Item"
                            ),
                            icon: _vm.$vuetify.breakpoint.smAndDown,
                            text: !_vm.$vuetify.breakpoint.smAndDown,
                          },
                          on: { confirmed: _vm.openForNew },
                        }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("AsyentSimpleTable", {
            attrs: {
              "read-only": _vm.readonly,
              "view-mode": _vm.viewMode,
              headers: _vm.itemHeadings,
              "items-list": _vm.value2,
              "is-disabled-func": _vm.isDisabledFunc,
              "edit-function": _vm.openForEdit,
              "view-function": _vm.openForView,
              "delete-function": _vm.deleteSelected,
              "show-expand": "",
              "single-expand": "",
              "item-key": "itemNumber",
              "sort-by": "itemNumber",
              "sort-desc": false,
              name: "consignmentItems.table",
            },
            scopedSlots: _vm._u([
              {
                key: "expanded-item",
                fn: function (ref) {
                  var headers = ref.headers
                  var item = ref.item
                  return [
                    _c(
                      "td",
                      { attrs: { colspan: headers.length } },
                      [
                        _c("v-data-table", {
                          attrs: {
                            headers: [
                              {
                                text: _vm.ae$lng("Equipment Number"),
                                value: "equipmentNumber",
                              },
                              {
                                text: _vm.ae$lng("Stuffed Gross Volume"),
                                value: "stuffedGrossVolume",
                              },
                              {
                                text: _vm.ae$lng("Stuffed Gross Weight"),
                                value: "stuffedGrossWeight",
                              },
                            ],
                            items: item.stuffedEquipments,
                            "items-per-page": 10,
                            "item-key": "equipmentNumber",
                            "hide-default-footer": _vm.value2.length <= 10,
                            "no-data-text": _vm.ae$lng("No Data Found"),
                            "footer-props": {
                              showFirstLastPage: true,
                            },
                            name: "stuffedEquipments.table.item",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("br"),
          _c("br"),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            persistent: "",
            scrollable: "",
            transition: "dialog-bottom-transition",
            width: "900px",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary", dark: "", "max-height": "56" } },
                [
                  _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
                  _c("v-spacer"),
                  !_vm.isNew && !_vm.noReset
                    ? _c("asyent-button", {
                        attrs: {
                          "button-icon": "mdi-reload",
                          "button-tooltip": _vm.ae$lng("Reset"),
                          icon: "",
                          "requires-confirmation": "",
                        },
                        on: { confirmed: _vm.resetItem },
                      })
                    : _vm._e(),
                  !_vm.isNew && !_vm.noDelete
                    ? _c("asyent-button", {
                        attrs: {
                          "button-icon": "mdi-delete",
                          "button-tooltip": _vm.ae$lng("Delete"),
                          icon: "",
                          "requires-confirmation": "",
                        },
                        on: { confirmed: _vm.deleteItem },
                      })
                    : _vm._e(),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-check",
                      "button-tooltip": _vm.ae$lng("Save"),
                      icon: "",
                      "requires-confirmation": "",
                    },
                    on: { confirmed: _vm.saveItem },
                  }),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-close",
                      "button-tooltip": _vm.ae$lng("Cancel"),
                      icon: "",
                      "requires-confirmation": "",
                    },
                    on: { confirmed: _vm.closeDialog },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "ma-0 pa-0",
                  style: {
                    "max-height": _vm.$vuetify.breakpoint.smAndUp
                      ? "500px"
                      : "",
                  },
                },
                [
                  _c(
                    "ValidationObserver",
                    { ref: _vm.internalFormReference, attrs: { disabled: "" } },
                    [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                          model: {
                            value: _vm.formValid,
                            callback: function ($$v) {
                              _vm.formValid = $$v
                            },
                            expression: "formValid",
                          },
                        },
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "asyent-card",
                                            {
                                              attrs: {
                                                color: "bgWhite",
                                                flat: "",
                                                title: "Item Details",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "3",
                                                      },
                                                    },
                                                    [
                                                      _vm.readonly
                                                        ? _c(
                                                            "asyent-form-display",
                                                            {
                                                              attrs: {
                                                                value: [
                                                                  _vm.itemData[
                                                                    "hsCode"
                                                                  ],
                                                                ],
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "hsCode"
                                                                  ),
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            "asyent-form-text",
                                                            {
                                                              attrs: {
                                                                "form-errors":
                                                                  _vm.formErrors,
                                                                "form-rules":
                                                                  _vm.formFieldsRules,
                                                                "is-disabled-func":
                                                                  _vm.isDisabled,
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "hsCode"
                                                                  ),
                                                                name: "consignmentItems.hsCode",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemData[
                                                                    "hsCode"
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "hsCode",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "itemData['hsCode']",
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "3",
                                                      },
                                                    },
                                                    [
                                                      _vm.readonly
                                                        ? _c(
                                                            "asyent-form-display",
                                                            {
                                                              attrs: {
                                                                value: [
                                                                  _vm.itemData[
                                                                    "commodityCode"
                                                                  ],
                                                                ],
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "commodityCode"
                                                                  ),
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            "asyent-form-text",
                                                            {
                                                              attrs: {
                                                                "form-errors":
                                                                  _vm.formErrors,
                                                                "form-rules":
                                                                  _vm.formFieldsRules,
                                                                "is-disabled-func":
                                                                  _vm.isDisabled,
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "commodityCode"
                                                                  ),
                                                                name: "consignmentItems.commodityCode",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemData[
                                                                    "commodityCode"
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "commodityCode",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "itemData['commodityCode']",
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _vm.readonly
                                                        ? _c(
                                                            "asyent-form-display",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.itemData[
                                                                    "description"
                                                                  ],
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "description"
                                                                  ),
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            "asyent-form-text",
                                                            {
                                                              attrs: {
                                                                "form-errors":
                                                                  _vm.formErrors,
                                                                "form-rules":
                                                                  _vm.formFieldsRules,
                                                                "is-disabled-func":
                                                                  _vm.isDisabled,
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "description"
                                                                  ),
                                                                name: "consignmentItems.description",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemData[
                                                                    "description"
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "description",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "itemData['description']",
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "3",
                                                      },
                                                    },
                                                    [
                                                      _vm.readonly
                                                        ? _c(
                                                            "asyent-form-display",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.itemData[
                                                                    "grossWeight"
                                                                  ],
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "grossWeight"
                                                                  ),
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            "asyent-form-text",
                                                            {
                                                              attrs: {
                                                                "form-errors":
                                                                  _vm.formErrors,
                                                                "form-rules":
                                                                  _vm.formFieldsRules,
                                                                "is-disabled-func":
                                                                  _vm.isDisabled,
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "grossWeight"
                                                                  ),
                                                                name: "consignmentItems.grossWeight",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemData[
                                                                    "grossWeight"
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "grossWeight",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "itemData['grossWeight']",
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "3",
                                                      },
                                                    },
                                                    [
                                                      _vm.readonly
                                                        ? _c(
                                                            "asyent-form-display",
                                                            {
                                                              attrs: {
                                                                value: [
                                                                  _vm.itemData[
                                                                    "transportPackages"
                                                                  ][
                                                                    "packageNumber"
                                                                  ],
                                                                  _vm.itemData[
                                                                    "transportPackages"
                                                                  ][
                                                                    "packageTypeCode"
                                                                  ],
                                                                  _vm.itemData[
                                                                    "transportPackages"
                                                                  ][
                                                                    "packageTypeName"
                                                                  ],
                                                                ],
                                                                label:
                                                                  "Packages",
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            "asyent-form-text",
                                                            {
                                                              attrs: {
                                                                "form-errors":
                                                                  _vm.formErrors,
                                                                "form-rules":
                                                                  _vm.formFieldsRules,
                                                                "is-disabled-func":
                                                                  _vm.isDisabled,
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "packageNumber"
                                                                  ),
                                                                name: "consignmentItems.packageNumber",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemData[
                                                                    "transportPackages"
                                                                  ][
                                                                    "packageNumber"
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .itemData[
                                                                        "transportPackages"
                                                                      ],
                                                                      "packageNumber",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "itemData['transportPackages']['packageNumber']",
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "3",
                                                      },
                                                    },
                                                    [
                                                      !_vm.readonly
                                                        ? _c(
                                                            "asyent-form-catalog",
                                                            {
                                                              attrs: {
                                                                sortable: "",
                                                                "form-errors":
                                                                  _vm.formErrors,
                                                                "form-rules":
                                                                  _vm.formFieldsRules,
                                                                "is-disabled-func":
                                                                  _vm.isDisabled,
                                                                "service-name":
                                                                  "catalog",
                                                                catalog:
                                                                  "CatalogPackageCode",
                                                                "show-item-code":
                                                                  "",
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "packageTypeCode"
                                                                  ),
                                                                "item-value":
                                                                  "code",
                                                                "item-text":
                                                                  "description",
                                                                name: "consignmentItems.packageTypeCode",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemData[
                                                                    "transportPackages"
                                                                  ][
                                                                    "packageTypeCode"
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .itemData[
                                                                        "transportPackages"
                                                                      ],
                                                                      "packageTypeCode",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "itemData['transportPackages']['packageTypeCode']",
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _vm.readonly
                                                        ? _c(
                                                            "asyent-form-display",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  _vm.itemData[
                                                                    "transportPackages"
                                                                  ][
                                                                    "shippingMarks"
                                                                  ],
                                                                label:
                                                                  "shippingMarks",
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            "asyent-form-text",
                                                            {
                                                              attrs: {
                                                                "form-errors":
                                                                  _vm.formErrors,
                                                                "form-rules":
                                                                  _vm.formFieldsRules,
                                                                "is-disabled-func":
                                                                  _vm.isDisabled,
                                                                label:
                                                                  _vm.ae$lng(
                                                                    "shippingMarks"
                                                                  ),
                                                                name: "consignmentItems.shippingMarks",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemData[
                                                                    "transportPackages"
                                                                  ][
                                                                    "shippingMarks"
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .itemData[
                                                                        "transportPackages"
                                                                      ],
                                                                      "shippingMarks",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "itemData['transportPackages']['shippingMarks']",
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "asyent-card",
                                                        {
                                                          attrs: {
                                                            color: "bgWhite",
                                                            flat: "",
                                                            title: "Stuffing",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              !_vm.readonly
                                                                ? {
                                                                    key: "toolbar-actions",
                                                                    fn: function () {
                                                                      return [
                                                                        _c(
                                                                          "asyent-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "view-mode":
                                                                                  _vm.viewMode,
                                                                                "is-disabled-func":
                                                                                  _vm.isDisabled,
                                                                                "button-tooltip":
                                                                                  _vm.ae$lng(
                                                                                    "Add"
                                                                                  ),
                                                                                text: "",
                                                                                label:
                                                                                  _vm.ae$lng(
                                                                                    "Add"
                                                                                  ),
                                                                                name: "stuffedEquipments.add",
                                                                              },
                                                                            on: {
                                                                              confirmed:
                                                                                _vm.addStuffedEquipment,
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    },
                                                                    proxy: true,
                                                                  }
                                                                : null,
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c(
                                                            "ValidationObserver",
                                                            {
                                                              ref: _vm.internalSubFormReference,
                                                              attrs: {
                                                                disabled: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-form",
                                                                {
                                                                  on: {
                                                                    submit:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                      },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.formValid,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.formValid =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "formValid",
                                                                  },
                                                                },
                                                                [
                                                                  !_vm.viewMode
                                                                    ? _c(
                                                                        "v-row",
                                                                        [
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                  md: "4",
                                                                                },
                                                                            },
                                                                            [
                                                                              !_vm.readonly
                                                                                ? _c(
                                                                                    "asyent-form-text",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "view-mode":
                                                                                            _vm.viewMode,
                                                                                          "form-errors":
                                                                                            _vm.formErrors,
                                                                                          "form-rules":
                                                                                            _vm.equipmentFieldsRules,
                                                                                          "is-disabled-func":
                                                                                            _vm.isDisabled,
                                                                                          label:
                                                                                            _vm.ae$lng(
                                                                                              "Equipment Number"
                                                                                            ),
                                                                                          name: "stuffedEquipment.number",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .stuffedEquipment[
                                                                                              "equipmentNumber"
                                                                                            ],
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.stuffedEquipment,
                                                                                                "equipmentNumber",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "stuffedEquipment['equipmentNumber']",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                  md: "4",
                                                                                },
                                                                            },
                                                                            [
                                                                              !_vm.readonly
                                                                                ? _c(
                                                                                    "asyent-form-text",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "view-mode":
                                                                                            _vm.viewMode,
                                                                                          "form-errors":
                                                                                            _vm.formErrors,
                                                                                          "form-rules":
                                                                                            _vm.equipmentFieldsRules,
                                                                                          "is-disabled-func":
                                                                                            _vm.isDisabled,
                                                                                          label:
                                                                                            _vm.ae$lng(
                                                                                              "Stuffed Gross Volume"
                                                                                            ),
                                                                                          name: "stuffedEquipment.volume",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .stuffedEquipment[
                                                                                              "stuffedGrossVolume"
                                                                                            ],
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.stuffedEquipment,
                                                                                                "stuffedGrossVolume",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "stuffedEquipment['stuffedGrossVolume']",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  cols: "12",
                                                                                  md: "4",
                                                                                },
                                                                            },
                                                                            [
                                                                              !_vm.readonly
                                                                                ? _c(
                                                                                    "asyent-form-text",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "view-mode":
                                                                                            _vm.viewMode,
                                                                                          "form-errors":
                                                                                            _vm.formErrors,
                                                                                          "form-rules":
                                                                                            _vm.equipmentFieldsRules,
                                                                                          "is-disabled-func":
                                                                                            _vm.isDisabled,
                                                                                          label:
                                                                                            _vm.ae$lng(
                                                                                              "Stuffed Gross Weight"
                                                                                            ),
                                                                                          name: "stuffedEquipment.weight",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .stuffedEquipment[
                                                                                              "stuffedGrossWeight"
                                                                                            ],
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.stuffedEquipment,
                                                                                                "stuffedGrossWeight",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "stuffedEquipment['stuffedGrossWeight']",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c("v-data-table", {
                                                            staticClass:
                                                              "pa-0 ma-0",
                                                            attrs: {
                                                              headers:
                                                                _vm.stuffingTableHeaders,
                                                              items:
                                                                _vm.itemData[
                                                                  "stuffedEquipments"
                                                                ],
                                                              "items-per-page":
                                                                -1,
                                                              "hide-default-footer":
                                                                "",
                                                              "multi-sort": "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                !_vm.viewMode
                                                                  ? {
                                                                      key: "item.actions",
                                                                      fn: function (
                                                                        ref
                                                                      ) {
                                                                        var item =
                                                                          ref.item
                                                                        return [
                                                                          _c(
                                                                            "asyent-button",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  "button-icon":
                                                                                    "mdi-pencil",
                                                                                  "button-tooltip":
                                                                                    _vm.ae$lng(
                                                                                      "Edit"
                                                                                    ),
                                                                                  icon: "",
                                                                                  "requires-confirmation":
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                confirmed:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.editEquipment(
                                                                                      item
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "asyent-button",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  "button-icon":
                                                                                    "mdi-delete",
                                                                                  "button-tooltip":
                                                                                    _vm.ae$lng(
                                                                                      "Delete"
                                                                                    ),
                                                                                  icon: "",
                                                                                  "requires-confirmation":
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                confirmed:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.deleteEquipment(
                                                                                      item
                                                                                    )
                                                                                  },
                                                                              },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      },
                                                                    }
                                                                  : null,
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }